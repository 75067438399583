import React, { FC } from 'react';
import { Step } from './components';
import { ProgressStepsContainer } from './ProgressSteps.styles';
import { IStep } from '../../NewMandateForm';

export interface Props {
  activeStep: number;
  steps: IStep[];
}

const ProgressSteps: FC<Props> = props => {
  const { activeStep = 0, steps, children, ...rest } = props;
  const progress = (activeStep / steps.length) * 100;

  return (
    <ProgressStepsContainer
      {...rest}
      role="progressbar"
      aria-valuenow={progress}
    >
      {steps.map((step, i) => (
        <Step
          beforeActive={i <= activeStep}
          afterActive={i < activeStep}
          key={step.name}
        >
          {step.name}
        </Step>
      ))}
    </ProgressStepsContainer>
  );
};

export default ProgressSteps;
