import { defaultTransition } from './layout';
import { hexToHSLPressed } from './colors';

export const getLinkShadow = color => `inset 0px -3px 0px 0px ${color}`;

export const defaultHover = `
  text-decoration: none;
  transition: ${defaultTransition};
  box-shadow: #33394344 0 2px 4px 0;
`;

export const defaultActive = hex => `
  background: ${hexToHSLPressed(hex)};
  transition: none;
`;
