import { darkBlueLight } from 'src/styles/colors';
import styled from 'styled-components';
import { IStep } from '../types';

export const CircleSpan = styled.span<IStep>`
  text-align: center;
  display: block;
  margin: 10px 2em;
  color: ${props => (props.active ? darkBlueLight : '#003755')};
  pointer-events: none;
  cursor: default;
  transition: all 0.2s ease-out;

  &:before {
    content: '';
    display: inline-block;
    width: 14px;
    height: 14px;
    border-radius: 7px;
    position: absolute;
    top: 5px;
    left: calc(50% - 4px);
    background-color: ${props => (props.active ? darkBlueLight : '#DBE0DE')};
    z-index: 1;
  }
`;
