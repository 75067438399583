import React, { StatelessComponent } from 'react';
import { IStep } from '../types';
import { CircleSpan } from './Circle.styles';

const Circle: StatelessComponent<IStep> = props => {
  const { active } = props;

  return <CircleSpan active={active}>{props.children}</CircleSpan>;
};

export default Circle;
