import { darkBlueLight } from 'src/styles/colors';
import styled from 'styled-components';
import { IStep } from './models';

const verticalLine = `
  content: "";
  display: inline-block;
  width: 50%;
  height: 3px;
  position: absolute;
  top: 10px;
  background-color: #DBE0DE;
`;

export const StepContainer = styled.div<IStep>`
  padding: 1.5em 0 0;
  margin: 0;
  text-align: center;
  display: inline-block;
  position: relative;

  &:not(:first-child):before {
    ${verticalLine}
    left: 0;
    background-color: ${props =>
      props.beforeActive ? darkBlueLight : '#DBE0DE'};
  }

  &:not(:last-child):after {
    ${verticalLine}
    right: 0;
    background-color: ${props =>
      props.afterActive ? darkBlueLight : '#DBE0DE'};
  }
`;
