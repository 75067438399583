import React, { StatelessComponent } from 'react';
import Circle from '../Circle';
import { IStep } from './models';
import { StepContainer } from './Step.styles';

const Step: StatelessComponent<IStep> = props => {
  const { beforeActive, afterActive, children, ...rest } = props;

  return (
    <StepContainer
      beforeActive={beforeActive}
      afterActive={afterActive}
      {...rest}
    >
      <Circle active={beforeActive}>{props.children}</Circle>
    </StepContainer>
  );
};

export default Step;
